body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #000 !important;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@font-face {
  font-family: "Euclid Flex";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/EuclidFlex-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Euclid Flex";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/EuclidFlex-RegularItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Euclid Flex";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/EuclidFlex-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Euclid Flex";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/EuclidFlex-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Euclid Flex";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/EuclidFlex-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Euclid Flex";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/EuclidFlex-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
}
